import Accordion from './Accordion/Accordion.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    Accordion,
    TxButton
  },
  computed: {
    accordionContent () {
      return [
        {
          title: this.i18nSection('mainpageQuestion1'),
          text: this.i18nSection('mainpageAnswer1')
        },
        {
          title: this.i18nSection('mainpageQuestion2'),
          text: this.i18nSection('mainpageAnswer2')
        },
        {
          title: this.i18nSection('mainpageQuestion3'),
          text: this.i18nSection('mainpageAnswer3')
        }
      ]
    }
  },
  methods: {
    goToFaq () {
      this.$router.push({
        path: this.createLocalizedLink('/faq')
      })
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  }
}
