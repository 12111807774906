import { mapActions, mapGetters, mapState } from 'vuex'
import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'

import MarketsSection from '@/pages/lib/sections/MarketsSection/MarketsSection.vue'
import MarketsPlaceholderSection from '@/pages/lib/sections/MarketsPlaceholderSection/MarketsPlaceholderSection.vue'
import WeAreGrootSection from '@/pages/lib/sections/WeAreGrootSection/WeAreGrootSection.vue'
import AdventureTimeSection from '@/pages/lib/sections/AdventureTimeSection/AdventureTimeSection.vue'
import PartnersSection from '@/pages/lib/sections/PartnersSection/PartnersSection.vue'
import LearnMoreSection from '@/pages/lib/sections/LearnMoreSection/LearnMoreSection.vue'

import PromoSection from './PromoSection/PromoSection.vue'
import NoBankSection from './NoBankSection/NoBankSection.vue'
import FeaturesSection from './FeaturesSection/FeaturesSection.vue'
import ChooseTimeSection from './ChooseTimeSection/ChooseTimeSection.vue'
import PressSection from './PressSection/PressSection.vue'
import FaqSection from './FaqSection/FaqSection.vue'
import RecentPostsSection from '@/pages/lib/BlogPages/BlogMainPage/RecentPostsSection/RecentPostsSection.vue'

export default {
  components: {
    NavBar,
    PromoSection,
    MarketsSection,
    MarketsPlaceholderSection,
    NoBankSection,
    FeaturesSection,
    ChooseTimeSection,
    WeAreGrootSection,
    AdventureTimeSection,
    PartnersSection,
    PressSection,
    LearnMoreSection,
    FaqSection,
    RecentPostsSection,
    PublicFooter
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    ...mapState({
      partners: state => state.public.partners
    })
  },
  methods: {
    ...mapActions({
      loadArticles: 'blog/loadArticles',
      loadCategories: 'blog/loadCategories'
    })
  },
  async serverPrefetch () {
    await Promise.all([
      this.loadCategories(),
      this.loadArticles({
        category: this.category,
        tags: this.tags,
        query: this.query,
        page: 0,
        size: 3
      })
    ])
  },
  async created () {
    await Promise.all([
      this.loadCategories(),
      this.loadArticles({
        category: this.category,
        tags: this.tags,
        query: this.query,
        page: 0,
        size: 3
      })
    ])
  }
}
