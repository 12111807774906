import { mapState, mapActions } from 'vuex'
import MarketItem from './MarketItem/MarketItem.vue'

export default {
  components: {
    MarketItem
  },
  computed: {
    ...mapState({
      passport: state => state.passport?.user
    }),
    ...mapState({
      publicMarkets: state => state.markets.list
    }),
    markets () {
      if (!this.publicMarkets.isLoaded) {
        return []
      }

      return this.publicMarkets.value
        .filter(e => !e.market.locked)
        .filter(e => e.market.promotional)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .slice(0, 3)
    }
  },
  watch: {
    publicMarkets: {
      handler () {
        if (this.markets.length === 0) {
          return
        }

        this.loadTickers({ markets: this.markets })
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      loadTickers: 'tickers/loadTickers'
    }),
    goToTrading () {
      if (this.passport) {
        this.openPrivateUrl('private/convert')
      } else {
        this.openPrivateUrl(`private/trading/${this.markets[0].market.symbol}`)
      }
    }
  }
}
