
export default {
  props: {
    title: String
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    expandList () {
      this.expanded = !this.expanded
    }
  }
}
