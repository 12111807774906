import { render, staticRenderFns } from "./PressItem.htm?vue&type=template&id=e3b0096e&scoped=true&"
import script from "./PressItem.js?vue&type=script&lang=js&"
export * from "./PressItem.js?vue&type=script&lang=js&"
import style0 from "./PressItem.scss?vue&type=style&index=0&id=e3b0096e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3b0096e",
  null
  
)

export default component.exports