import WithBackgroundImagePartial from '@/pages/lib/partials/WithBackgroundImagePartial/WithBackgroundImagePartial.vue'

export default {
  components: {
    WithBackgroundImagePartial
  },
  computed: {
    items () {
      return [
        {
          itemTitle: this.i18nSection('noBankTitle1'),
          itemText: this.i18nSection('mainpageNoBankText1'),
          titleAsH2: true
        },
        {
          itemTitle: this.i18nSection('noBankTitle2'),
          itemText: this.i18nSection('mainpageNoBankText2'),
          titleAsH2: true
        },
        {
          itemTitle: this.i18nSection('noBankTitle3'),
          itemText: this.i18nSection('mainpageNoBankText3'),
          titleAsH2: true
        }
      ]
    }
  }
}
