import { mapGetters, mapActions, mapState } from 'vuex'
import ScrollItemsPartial from '@/pages/lib/partials/ScrollItemsPartial/ScrollItemsPartial.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import Spinner from '@/components/lib/Spinner/Spinner.vue'
import PressItem from './PressItem/PressItem.vue'

export default {
  components: {
    ScrollItemsPartial,
    PressItem,
    TxButton,
    Spinner
  },
  async created () {
    await this.loadNews({ locale: this.language })
  },
  computed: {
    ...mapState({
      news: state => state.public.news
    }),
    ...mapGetters({
      language: 'language/language'
    }),
    isNewsLoading () {
      return this.news == null
    },
    content () {
      return {
        title: this.i18nSection('mainpagePressSectionTitle')
      }
    }
  },
  methods: {
    ...mapActions({
      loadNews: 'public/loadNews'
    }),
    goToBlog () {
      this.$router.push({
        path: this.createLocalizedLink('/blog')
      })
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  }
}
