import { mapActions, mapGetters } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import OpenModalLink from '@/components/lib/OpenModalLink/OpenModalLink.vue'

export default {
  components: {
    TxButton,
    OpenModalLink
  },
  data () {
    return {
      show: 0,
      isMobile: false
    }
  },
  mounted () {
    if (window.innerWidth <= 768) {
      this.isMobile = true
    }
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeListener)
  },
  computed: {
    ...mapGetters({
      getExternalLinkObject: 'public/getExternalLinkObject'
    }),
    tabs () {
      return [
        {
          index: '01',
          title: this.i18nSection('mainpageTabTitle1'),
          description: this.i18nSection('mainpageTabDescription1'),
          src: '/src/trade.jpg',
          video: [
            {
              title: this.i18nSection('featureBlockVideoLinkTitle1'),
              src: this.youtubeVideoSrc('Main', 'ModalVideo2_')
            },
            {
              title: this.i18nSection('featureBlockVideoLinkTitle2')
            },
            {
              title: this.i18nSection('featureBlockVideoLinkTitle3')
            }
          ]
        },
        {
          index: '02',
          title: this.i18nSection('mainpageTabTitle3'),
          description: this.i18nSection('mainpageTabDescription3'),
          src: '/src/balances.jpg',
          video: [
            {
              title: 'How to Deposit funds 1'
            },
            {
              title: 'How to Convert funds 1'
            }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open'
    }),
    resizeListener () {
      if (window.innerWidth <= 768) {
        this.isMobile = true
      }
    },
    youtubeVideoSrc (page, key) {
      const params = this.getExternalLinkObject(`${page}`, `${key}${this.language}`)
      if (params) {
        const url = new URL(params.link)
        url.searchParams.append('autoplay', 'true')
        return url.href
      }
    }
  }
}
