import { mapState, mapActions } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    TxButton
  },
  data () {
    return {
      isBusySignUp: false,
      isBusySignIn: false
    }
  },
  computed: {
    ...mapState({
      passport: state => state.passport?.user
    })
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    async handleSignIn () {
      this.isBusySignIn = true
      await this.authInitiate()
    },
    async handleSignUp () {
      this.isBusySignUp = true
      await this.authInitiate({ mode: 'signup' })
    }
  }
}
