import ScrollItemsPartial from '@/pages/lib/partials/ScrollItemsPartial/ScrollItemsPartial.vue'
import ChooseTimeItem from './ChooseTimeItem/ChooseTimeItem.vue'

export default {
  components: {
    ChooseTimeItem,
    ScrollItemsPartial
  },
  computed: {
    content () {
      return {
        title: this.i18nSection('mainpageChooseTimeSectionTitle'),
        description: this.i18nSection('mainpageChooseTimeSectionDescription')
      }
    },
    chooseTimeItems () {
      return [
        {
          src: '/src/hand1.svg',
          title: this.i18nSection('chooseTimeTitle1'),
          text: this.i18nSection('chooseTimeText1')
        },
        {
          src: '/src/hand4.svg',
          title: this.i18nSection('mainpageChooseTimeTitle2'),
          text: this.i18nSection('mainpageChooseTimeText2')
        },
        {
          src: '/src/hand3.svg',
          title: this.i18nSection('mainpageChooseTimeTitle3'),
          text: this.i18nSection('mainpageChooseTimeText3')
        }
      ]
    }
  }
}
