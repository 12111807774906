import { mapActions } from 'vuex'
import VideoModal from '@/modals/lib/VideoModal/VideoModal.vue'

export default {
  props: {
    video: Object
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open'
    }),
    openModalVideo () {
      this.openModal({
        factory: () => VideoModal,
        data: {
          video: this.video
        }
      })
    }
  }
}
