import AccordionItem from '../AccordionItem/AccordionItem.vue'

export default {
  components: {
    AccordionItem
  },
  props: {
    content: Array
  }
}
