import WeAreGrootItem from './WeAreGrootItem/WeAreGrootItem.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    WeAreGrootItem
  },
  data () {
    return {
      isShowVideo: false
    }
  },
  computed: {
    ...mapGetters({
      getExternalLinkObject: 'public/getExternalLinkObject'
    }),
    featuresItems () {
      return [
        {
          firstLineText: this.i18nSection('weAreGrootItem1Title'),
          secondLineText: this.i18nSection('weAreGrootItem1Text'),
          link: {
            text: this.i18nSection('mainpageWeAreGrootItem1LinkText'),
            url: '/about-us'
          }
        },
        {
          firstLineText: this.i18nSection('weAreGrootItem2Title'),
          secondLineText: this.i18nSection('weAreGrootItem2Text'),
          link: {
            text: '',
            url: ''
          }
        },
        {
          firstLineText: this.i18nSection('weAreGrootItem3Title'),
          secondLineText: this.i18nSection('weAreGrootItem3Text'),
          link: {
            text: '',
            url: ''
          }
        }
      ]
    },
    youtubeVideoSrc () {
      const url = new URL(this.getExternalLinkObject('Main', `AboutTimexVideo_${this.language}`).link)
      url.searchParams.append('autoplay', 'true')
      return url.href
    }
  },
  methods: {
    showVideo () {
      this.isShowVideo = true
    }
  }
}
