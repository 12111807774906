import TxButton from '@/components/lib/TxButton/TxButton.vue'
import WithBackgroundImagePartial from '@/pages/lib/partials/WithBackgroundImagePartial/WithBackgroundImagePartial.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    TxButton,
    WithBackgroundImagePartial
  },
  data () {
    return {
      isBusySignUp: false,
      isBusySignIn: false
    }
  },
  computed: {
    ...mapState({
      passport: state => state.passport?.user
    }),
    promoContent () {
      return {
        title: this.i18nSection('mainpagePromoTitle'),
        description: this.i18nSection('mainpagePromoDescription'),
        subtitle: this.i18nSection('mainpagePromoSubTitle')
      }
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    async handleSignIn () {
      this.isBusySignIn = true
      await this.authInitiate()
    },
    async handleSignUp () {
      this.isBusySignUp = true
      await this.authInitiate({ mode: 'signup' })
    }
  }
}
