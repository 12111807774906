import { mapState, mapActions, mapGetters } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import TwoColumnHeaderPartial from '@/pages/lib/partials/TwoColumnHeaderPartial/TwoColumnHeaderPartial.vue'
import OpenModalLink from '@/components/lib/OpenModalLink/OpenModalLink.vue'

export default {
  components: {
    TwoColumnHeaderPartial,
    TxButton,
    OpenModalLink
  },
  data () {
    return {
      playLoop: false,
      isBusySignUp: false,
      isBusySignIn: false
    }
  },
  computed: {
    ...mapGetters({
      getExternalLinkObject: 'public/getExternalLinkObject'
    }),
    ...mapState({
      passport: state => state.passport?.user
    }),
    promoContent () {
      return {
        title: this.i18nSection('mainpagePromoTitle'),
        description: this.i18nSection('mainpagePromoDescription'),
        subtitle: this.i18nSection('mainpagePromoSubTitle')
      }
    },
    video () {
      return {
        title: this.i18nSection('promoBlockVideoLinkTitle'),
        src: this.youtubeVideoSrc('Main', 'ModalVideo1_')
      }
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    youtubeVideoSrc (page, key) {
      const params = this.getExternalLinkObject(`${page}`, `${key}${this.language}`)
      if (params) {
        const url = new URL(params.link)
        url.searchParams.append('autoplay', 'true')
        return url.href
      }
    },
    async handleSignIn () {
      this.isBusySignIn = true
      await this.authInitiate()
    },
    async handleSignUp () {
      this.isBusySignUp = true
      await this.authInitiate({ mode: 'signup' })
    }
  },
  async mounted () {
    setTimeout(() => {
      this.playLoop = true
    }, 6380)
  }
}
