import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    video: Object
  },
  components: {
  },
  data () {
    return {
      isShowVideo: false
    }
  },
  computed: {
    ...mapGetters({
      getExternalLinkObject: 'public/getExternalLinkObject'
    })
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    }),
    showVideo () {
      this.isShowVideo = true
    }
  }
}
